<div *ngIf="activeCustomer$ | async as customer; else notLoggedIn">
    <a class="btn btn-dark" [routerLink]="['/account']">
        <fa-icon icon="user-circle"></fa-icon>
        <span class="d-none d-md-inline ml-2">{{ userName(customer) }}</span>
    </a>
</div>
<ng-template #notLoggedIn>
    <a class="btn btn-dark" (click)="signOut()">
        <fa-icon icon="user-circle"></fa-icon>
        <span class="d-none d-md-inline ml-2">Ausloggen</span>
    </a>
</ng-template>
