<div *ngIf="cart$ | async as cart;">
    <button class="btn btn-dark position-relative"
            (click)="toggle.emit()"
            title="cart">
        <fa-icon icon="shopping-cart" class="mr-1"></fa-icon>
        <span class="badge badge-pill badge-secondary qty-indicator"
              [class.empty]="cart.quantity === 0"
              [class.cart-changed]="cartChangeIndication$ | async">{{ cart.quantity }}</span>
    </button>
</div>
