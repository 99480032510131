<button type="button" class="close text-white" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
</button>
<a [routerLink]="['/']" class="logo-link ml-3 mb-4" (click)="close()">
    <img src="assets/logo-on-dark.png" class="logo" alt="Canify" style="max-height: 50px;">
</a>
<ul class="" *ngIf="collectionTree$ | async as tree">
    <li class="level0" *ngFor="let collection of tree.children">
        <a class="" style="cursor: pointer"
           (click)="onL0Click($event, collection)">{{ collection.name }}</a>
        <ul *ngIf="collection.children.length"
            class="expandable"
            [class.expanded]="selected0 === collection.id">
            <li class="level1" *ngFor="let level1 of collection.children">
                <a class="" style="cursor: pointer"
                   (click)="onL1Click($event, level1)">{{ level1.name }}</a>
                <ul *ngIf="level1.children.length"
                    class="expandable"
                    [class.expanded]="selected1 === level1.id">
                    <li class="level2" *ngFor="let level2 of level1.children">
                        <a class="" style="cursor: pointer"
                           (click)="close()"
                           [routerLink]="['/category', level2.slug ]">{{ level2.name }}</a>
                    </li>
                </ul>
            </li>
        </ul>
    </li>
</ul>
<div class="flex-grow-1"></div>
