<div *ngIf="cart" class="cart-contents">
    <small class="mb-3">Alle Angaben zzgl. MwSt.</small>
    <div class="items">
        <ng-container *ngFor="let line of cart.lines; trackBy: trackByFn">
            <div class="item" [class.has-discount]="isDiscounted(line)">
                <!-- <div class="thumb">
                    <img [src]="line.featuredAsset?.preview + '?preset=tiny'" />
                </div> -->
                <div class="details">
                    <div class="name">{{ line.productVariant?.name }}</div>
                    <div class="figures">
                        <div class="price">{{ line.unitPrice | formatPrice }}</div>
                        <div class="qty-controls">
                            <button
                                    class="btn btn-sm"
                                    *ngIf="canAdjustQuantities"
                                    (click)="decrement(line)"
                                    alt="Remove 1"
                            >
                                <fa-icon aria-label="Remove 1" icon="minus"></fa-icon>
                            </button>
                            <input type="text"
                                   #qty name="qty"
                                   [value]="line.quantity"
                                   (change)="set(line, qty.value)"
                                   class="form-control text-center mx-2 p-0" style="max-width: 60px">
                            <button
                                    class="btn btn-sm"
                                    *ngIf="canAdjustQuantities"
                                    (click)="increment(line)"
                            >
                                <fa-icon aria-label="Add 1" icon="plus"></fa-icon>
                            </button>
                        </div>

                        <div class="totals-controls">
                            <div class="total">
                                <div class="list-price">{{ line.linePrice | formatPrice }}</div>
                                <div class="discounted">
                                    {{ line.discountedLinePrice | formatPrice }}
                                </div>
                            </div>
                            <button
                                    class="btn btn-sm ml-2"
                                    *ngIf="canAdjustQuantities"
                                    (click)="set(line, 0)"
                            >
                                <fa-icon aria-label="Remove" icon="times"></fa-icon>
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="details">
        <div class="order-adjustments" *ngIf="cart.discounts.length">
            <span>Rabatte:</span>
            <div class="adjustment" *ngFor="let discount of cart.discounts">
                <div>{{ discount.description }}</div>
                <div>{{ discount.amount | formatPrice }}</div>
            </div>
        </div>
        <div *ngIf="cart.shipping > 0">
            <div class="shipping">
                <div class="shipping-price">
                    <span>Versand:</span>
                    <span>{{ cart.shipping | formatPrice }}</span>
                </div>
                <div class="shipping-method" *ngFor="let shippingLine of cart.shippingLines">
                    {{ shippingLine.shippingMethod.name }}
                </div>
            </div>
            <div class="totals">
                <span>Gesamt zzgl. MwSt.:</span>
                <span>{{ cart.total | formatPrice }}</span>
            </div>
            <div class="totals">
                <span>MwSt.:</span>
                <span>{{ cart.totalWithTax - cart.total | formatPrice }}</span>
            </div>
            <div class="totals">
                <span>Gesamt inkl. MwSt.:</span>
                <span>{{ cart.totalWithTax | formatPrice }}</span>
            </div>
        </div>
        <div *ngIf="cart.shipping == 0">
            <div class="shipping">
                <div class="shipping-price">
                    <span>Versand:</span>
                    <span>{{ cart.shipping | formatPrice }}</span>
                </div>
                <div class="shipping-method" *ngFor="let shippingLine of cart.shippingLines">
                    {{ shippingLine.shippingMethod.name }}
                </div>
            </div>
            <div class="totals">
                <span>Gesamt zzgl. MwSt.:</span>
                <span>{{ cart.total | formatPrice }}</span>
            </div>
            <div class="totals">
                <span>MwSt.:</span>
                <span>{{ cart.totalWithTax - cart.total + 200 | formatPrice }}</span>
            </div>
            <div class="totals">
                <span>Gesamt inkl. MwSt.:</span>
                <span>{{ cart.totalWithTax | formatPrice }}</span>
            </div>
        </div>
    </div>
</div>
