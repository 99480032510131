<div class="hero" [style.background-image]="heroImage" *ngIf="!(searchTerm$ | async) && !(collection$ | async)">
  <svg class="background" viewBox="0 0 1531 891" preserveAspectRatio="xMidYMax slice" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 182c144.327 47.973 534.462 219.712 440.509 369.87C346.555 702.028 79.877 662.846 0 638V182z"></path>
    <path d="M1405 848c-424.366 158.009-437-164-437-272s102-425 563-576v769c-21.333 29.333-63.333 55.667-126 79z"></path>
    <path
      d="M1531 162c-122.914-17.284-377.96 33.191-543.433 206.414C822.095 541.636 797.342 648.75 835.842 731.622c38.5 82.871 198.243 134.841 400.555 92.053C1438.71 780.886 1492.752 775.894 1531 768V162z"
    ></path>
  </svg>

  <h1 class="title text-center p-3">
    Herzlich willkommen im Canify-Webshop!<br />
    Wir freuen uns auf Ihre Bestellung.
  </h1>
</div>

<div class="masthead text-center text-md-left" [class.search-mode]="searchTerm$ | async">
  <div class="masthead-bg" [style.background-image]="mastheadBackground$ | async"></div>
  <div class="container position-relative">
    <vsf-collection-breadcrumbs [breadcrumbs]="breadcrumbs$ | async"></vsf-collection-breadcrumbs>
    <div class="py-4 container-title">
      <h3 class="collection-name" [ngClass]="checkFacetColor((collection$ | async)?.name)" *ngIf="collection$ | async">{{ (collection$ | async)?.name }}</h3>
      <h3 class="text-muted" *ngIf="searchTerm$ | async as term">
        Ergebnisse für <span class="text-dark">"{{ term }}"</span>
      </h3>
      <h3 class="text-primary" *ngIf="!(searchTerm$ | async) && !(collection$ | async)">Unsere Produkte</h3>
      <!-- <div *ngIf="isGridView; else gridIcon">
        <img src="assets/grid.png" alt="List View" height="24px" title="List View" role="button" (click)="toggleViewMode()" />
      </div>
      <ng-template #gridIcon>
        <img src="assets/list.png" alt="Grid View" height="24px" title="Grid View" role="button" (click)="toggleViewMode()" />
      </ng-template> -->
    </div>
    <div class="mb-4" *ngIf="collection$ | async" [innerHtml]="(collection$ | async).description"></div>
  </div>
</div>
<div class="container-table">
  <div class="row mt-4">
    <!-- <div class="col-12 col-md-3" *ngIf="isGridView">
      <vsf-product-list-controls
        [facetValues]="facetValues"
        [activeFacetValueIds]="activeFacetValueIds$ | async"
        [totalResults]="unfilteredTotalItems"
      ></vsf-product-list-controls>
    </div> -->
    <div class="col-12 col-md-9">
      <ng-container *ngIf="collection$ | async as collection">
        <div *ngIf="collection.children.length" class="child-categories d-flex flex-column flex-md-row">
          <vsf-collection-card *ngFor="let child of collection.children" [collection]="child"> </vsf-collection-card>
        </div>
      </ng-container>
      <ng-container *ngIf="(totalResults$ | async) !== 0; else noResults">
        <!-- <div *ngIf="isGridView; else listView" class="product-list">
          <ng-container *ngIf="(totalResults$ | async) !== null; else placeholders">
            <vsf-product-card *ngFor="let product of products$ | async; trackBy: trackByProductId" [product]="product"></vsf-product-card>
          </ng-container>
          <ng-template #placeholders>
            <vsf-product-card *ngFor="let product of placeholderProducts" [product]="product"></vsf-product-card>
          </ng-template>
          <div class="load-more flex-fill" *ngIf="displayLoadMore$ | async">
            <button class="btn btn-light btn-lg d-inline-flex align-items-center" (click)="loadMore()" [disabled]="loading$ | async">
              Mehr laden
              <span [class.show]="loading$ | async" class="loading-indicator spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
            </button>
          </div>
        </div> -->
        <!-- <ng-template #listView> -->
          <vsf-product-table></vsf-product-table> 
          <!-- here -->
        <!-- </ng-template> -->
      </ng-container>
    </div>
  </div>
</div>

<ng-template #noResults>
  <div class="no-results col-12">
    <h3>Keine Ergebnisse</h3>
    <!--        <fa-icon icon="sad-cry" size="10x"></fa-icon>-->
  </div>
</ng-template>
