<div class="cart-drawer-header">
    <h2 class="cart-title text-primary">Warenkorb</h2>
    <button class="btn" (click)="close.emit()">
        <fa-icon icon="times"></fa-icon>
    </button>
</div>
<div *ngIf="!(isEmpty$ | async); else empty" class="cart-drawer-contents">
    <vsf-cart-contents
        [cart]="cart$ | async"
        [canAdjustQuantities]="true"
        (setQuantity)="setQuantity($event)"
    ></vsf-cart-contents>
    <div class="checkout-button">
        <a
            class="btn btn-block btn-primary order-btn"
            color="primary"
            (click)="close.emit()"
            [routerLink]="['/checkout']"
            >Bestellen</a
        >
    </div>
</div>
<ng-template #empty>
    <div class="empty">Warenkorb ist leer</div>
</ng-template>
