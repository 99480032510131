export const environment = {
    production: true,
    // Shop API
    apiHost: 'https://admin.shop.canify.tekr.io',
    // apiHost: 'https://admin.shop.canify.com',
    apiPort: 443,
    shopApiPath: 'shop-api',
    baseHref: '/',
    tokenMethod: 'bearer',
    // Paypal
    paypalClientId: 'sb', // sandbox
    // Redirect to account module to re-validate token
    // redirectBackUrl: 'https://account.canify.com',
    redirectBackUrl: 'https://account.canify.tekr.io',
    // redirectBackUrl: 'https://account.canify.com/pharmacy/validate',
    // Sign-out
    // accountUrl: 'https://account.canify.com',
    accountUrl: 'https://account.canify.tekr.io',
    // Shop frontend URL
    // shopUrl: 'https://shop.canify.com'
    shopUrl: 'https://shop.canify.tekr.io',
    accountApiUrl: 'https://api.account.canify.tekr.io/v1',
    // API key to gain access to the API
    accountApiKey: '2v4sWvqwqzbvAPnHNMv7e8tMKf35YDY2QcuVKUsjxxyQHvmqkyAH2Fc25AjfyScV',
    accountAdminUser: 'admin@tekr.io',
    accountAdminPass: '9N8j1ZhWtuTtsK51cn9Rck8VBj6Wa9yt',
};
