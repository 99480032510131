import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, Injectable, TemplateRef } from '@angular/core';

import { AdjustItemQuantity, LogicalOperator, GetActiveOrder, GetCollection, RemoveItemFromCart, SearchProducts, TransitionToAddingItems } from '../../../common/generated-types';

import { BehaviorSubject, Observable, Subscribable, Subscriber, Subscription, combineLatest, merge, of } from 'rxjs';
import { distinctUntilChanged, map, mapTo, scan, share, shareReplay, skip, switchMap, take, tap } from 'rxjs/operators';

import { DataService } from '../../providers/data/data.service';
import { ProductListComponent } from '../product-list/product-list.component'
import { SEARCH_PRODUCTS_TABLE } from './product-table.graphql';
import { CartContentsComponent } from 'src/app/shared/components/cart-contents/cart-contents.component';
import { CartDrawerComponent } from '../cart-drawer/cart-drawer.component';
import { getRouteArrayParam } from '../../../common/utils/get-route-array-param';
import { ProductDetailComponent } from '../product-detail/product-detail.component';
import { ADJUST_ITEM_QUANTITY, GET_ACTIVE_ORDER, REMOVE_ITEM_FROM_CART } from '../cart-drawer/cart-drawer.graphql';
import { NotificationService } from '../../providers/notification/notification.service';
import { GET_COLLECTION, SEARCH_PRODUCTS, GET_ALL_COLLECTIONS } from '../product-list/product-list.graphql';
import { ActivatedRoute } from '@angular/router';
import { StateService } from '../../providers/state/state.service';
import { AssetPreviewPipe } from 'src/app/shared/pipes/asset-preview.pipe';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { TRANSITION_TO_ADDING_ITEMS } from '../../../checkout/components/checkout-process/checkout-process.graphql';

@Injectable({
  providedIn: 'root',
})
@Component({
  selector: 'vsf-product-table',
  templateUrl: './product-table.component.html',
  styleUrls: ['./product-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductTableComponent implements OnInit {
  productsListSubject = new BehaviorSubject<any[]>([]);
  productsList$: Observable<any[]> = this.productsListSubject.asObservable();
  productsList: any;
  cartOrders: any;
  hasProducts: boolean = false;
  private subscription: Subscription;
  products$: Observable<SearchProducts.Items[]>;
  totalResults$: Observable<number>;
  collection$: Observable<GetCollection.Collection | undefined>;
  allCollections$: any;
  facetValues: SearchProducts.FacetValues[] | undefined;
  unfilteredTotalItems = 0;
  activeFacetValueIds$: Observable<string[]>;
  searchTerm$: Observable<string>;
  displayLoadMore$: Observable<boolean>;
  loading$: Observable<boolean>;
  breadcrumbs$: Observable<Array<{ id: string; name: string }>>;
  mastheadBackground$: Observable<SafeStyle>;
  private currentPage = 0;
  private refresh = new BehaviorSubject<void>(undefined);
  readonly placeholderProducts = Array.from({ length: 12 }).map(() => null);
  private addToCartTemplate: TemplateRef<any>;
  // heroImage: SafeStyle;
  isGridView: boolean = false;
  constructor(
    private dataService: DataService,
    private notificationService: NotificationService,
    // private prodList: ProductListComponent,
    private addToCart: ProductDetailComponent,
    private route: ActivatedRoute,
    private stateService: StateService,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    this.prodList()
    this.allCollections$ = this.fetchAllCollections()
    const products$ = this.fetchAllProducts()
    const cart$ = this.fetchCart()

    combineLatest([products$, cart$, this.products$]).subscribe(([products, cart, searchedProducts]) => {
      if (cart.activeOrder) {
        this.hasProducts = cart.activeOrder.lines.length > 0 ? true : false
      }
      if (!cart || cart.activeOrder === null) {
        this.productsListSubject.next(products)
        // searchedProducts not getting all ids
        const sortedProducts = this.searchProducts(products, searchedProducts)
        this.productsListSubject.next(sortedProducts)
      } else {
        this.cartOrders = cart;
        const filteredProducts = this.filterOrderId(cart, products);
        const data = this.updateArrayData(false, filteredProducts, cart);
        // console.log(`I'm Updated :)`)
        const sortedProducts = this.searchProducts(data, searchedProducts)
        this.productsListSubject.next(sortedProducts); // Update the BehaviorSubject
      }
    });
    // to sort products by collections
    this.productsList$ = this.productsList$.pipe(
      map(products => {
        // console.log(products)
        return products.sort((a, b) => {
          if (a.products[0].collections[0].name < b.products[0].collections[0].name) return -1;
          if (a.products[0].collections[0].name > b.products[0].collections[0].name) return 1;
          return 0;
        });
      })
    );
  }

  ngOnDestroy(): void {
    this.productsListSubject.complete(); // Complete the subject to clean up
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  removeWeightFromName(name: string): string {
    return name.replace(/\s\d+\/?\d*g$/, '');
  }

  // recover all the products from vendure, with customValues included
  fetchAllProducts(): Observable<any> {
    return this.dataService.query(SEARCH_PRODUCTS_TABLE).pipe(
      map((result) => {
        const data = result.products.items
          // uncomment to only show products on stock
          .filter((item: any) => item.variants.some((variant: any) => variant.stockLevel === 'IN_STOCK'))
          .map((item: any) => ({
            ...item,
            quantity: 0,
            orderLineId: 0,
          }));
        return this.separateProductsByCultivar(data);
      })
    );
  }

  fetchCart(): Observable<any> {
    return this.dataService.query<GetActiveOrder.Query, GetActiveOrder.Variables>(
      GET_ACTIVE_ORDER,
      {},
      'network-only'
    ).pipe(
      map((res) => {
        this.cartOrders = res.activeOrder;
        return res;
      })
    );
  }

  fetchAllCollections() {
    return this.dataService.query(
      GET_ALL_COLLECTIONS).pipe(map((res) => {
        return res;
      })
      );
  }


  // this is the new data structure
  separateProductsByCultivar(products: any) {
    const cultivarMap = new Map<string, any>();
    for (const product of products) {
      const cultivar = product.customFields.cultivar;

      if (cultivarMap.has(cultivar)) {
        cultivarMap.get(cultivar).products.push(product);
      } else {
        // If cultivar doesn't exist in the map, create a new object with the cultivar name
        cultivarMap.set(cultivar, { cultivar: cultivar, products: [product] });
      }
    }
    const resultArray = Array.from(cultivarMap.values());
    return resultArray;
  }

  // control table values
  // this method filter order Id and updates it's quantity from the cart
  filterOrderId(order: any, products: any) {
    const productIdToOrderLineMap: { [productId: string]: any } = {};
    order.activeOrder.lines.forEach((orderLine: any) => {
      const productVariant = orderLine.productVariant;
      if (productVariant) {
        const productId = productVariant.id;
        productIdToOrderLineMap[productId] = orderLine;
      }
    });

    const filteredProducts = products.map((sorte: any) => {
      const result = sorte.products.map((product: any) => {
        const productVariantId = product.variants[0]?.id;
        if (productVariantId && productIdToOrderLineMap[productVariantId]) {
          const orderLine = productIdToOrderLineMap[productVariantId];
          const updatedProduct = { ...product, orderLineId: orderLine.id, quantity: orderLine.quantity };
          return updatedProduct;
        }
        return product

      })
      return { cultivar: sorte.cultivar, products: result };
    });
    return filteredProducts.filter((product: any) => product.products.some((p: any) => p.orderLineId !== undefined));
  }

  updateArrayData(autoUpdate?: boolean, productList?: any, cartOrders?: any) {
    const list = productList ? productList : this.productsList
    const order = cartOrders ? cartOrders : this.cartOrders

    let sourceArray = this.filterOrderId(order, list);
    let targetArray = list;
    const idToData: { [id: string]: any } = {};

    for (const item of sourceArray) {
      for (const variant of item.products) {
        idToData[variant.id] = variant;
      }
    }
    for (const item of targetArray) {
      for (const variant of item.products) {
        if (variant.id in idToData) {
          const sourceItem = idToData[variant.id];
          variant.quantity = sourceItem.quantity;
          variant.orderLineId = sourceItem.orderLineId;
        }
      }
    }
    // console.log('New Target Array: ', targetArray)
    if (autoUpdate) {
      this.productsListSubject.next(targetArray);
    }
    return targetArray;
  }

  searchProducts(products: any[], searchedProducts: any[]): any[] {

    const searchedProductIds = new Set(searchedProducts.map((searchedProduct) => searchedProduct.productId));

    return products
      .filter((productGroup) => productGroup.products.length > 0) // Exclude empty product groups
      .map((productGroup) => ({
        ...productGroup,
        products: productGroup.products.filter((product: any) =>
          searchedProductIds.has(product.id)
        ),
      }))
      .filter((productGroup) => productGroup.products.length > 0); // Exclude product groups with no matching products
  }

  decreaseValue(product: any) {

    if (product.quantity > 0 && product.orderLineId !== 0) {
      this.updateProducts(product.id, product.variants[0].id, this.cartOrders, product.quantity - 1);
      this.setItemQuantity({ itemId: product.orderLineId.toString(), quantity: parseInt(product.quantity) });
    }
    if (product.quantity <= 0 && product.orderLineId !== 0) {
      this.removeItem(product.orderLineId.toString())
      this.updateProducts(product.id, product.variants[0].id, this.cartOrders, 0);
      setTimeout(() => {
        this.updateProducts(product.id, product.variants[0].id, this.cartOrders, 0);
      }, 1000)
    } else if (product.orderLineId !== 0) {
      console.log('already empty')
    }
  }

  increaseValue(product: any) {
    if (product.quantity === 0) {
      this.updateProducts(product.id, product.variants[0].id, this.cartOrders, product.quantity + 1);
      try {
        if (this.cartOrders && this.cartOrders.activeOrder && this.cartOrders.activeOrder.state === 'ArrangingPayment') {
          this.dataService.mutate<TransitionToAddingItems.Mutation>(TRANSITION_TO_ADDING_ITEMS).subscribe(() => {
            this.addToCart.addToCart(product.variants[0], product.quantity);
          })
        } else {
          this.addToCart.addToCart(product.variants[0], product.quantity);
        }
        // this.notificationService
        //   .notify({
        //     title: 'Artikel hinzugefügt',
        //     type: 'info',
        //     duration: 3000,
        //     templateRef: this.addToCartTemplate,
        //     templateContext: {
        //       variant: product.variants[0],
        //       quantity: product.quantity,
        //     },
        //   })
        //   .subscribe();
      } catch (err) {
        this.updateProducts(product.id, product.variants[0].id, this.cartOrders, 0)
      }
    } else if (product.quantity !== 0 && product.orderLineId !== 0) {
      this.updateProducts(product.id, product.variants[0].id, this.cartOrders, product.quantity + 1);
      this.setItemQuantity({ itemId: product.orderLineId.toString(), quantity: parseInt(product.quantity) });
    }
  }

  set(event: any, product: any) {
    const value = parseInt(event.target.value)
    if (product.quantity !== 0 && value !== 0) {
      this.setItemQuantity({ itemId: product.orderLineId.toString(), quantity: value });
      this.updateProducts(product.id, product.variants[0].id, this.cartOrders, value)
    } else if (product.quantity === 0 && value !== 0) {
      if (this.cartOrders && this.cartOrders.activeOrder && this.cartOrders.activeOrder.state === 'ArrangingPayment') {
        this.dataService.mutate<TransitionToAddingItems.Mutation>(TRANSITION_TO_ADDING_ITEMS).subscribe(() => {
          this.addToCart.addToCart(product.variants[0], value);
          this.updateProducts(product.id, product.variants[0].id, this.cartOrders, value)
        })
      } else {
        this.addToCart.addToCart(product.variants[0], value);
        this.updateProducts(product.id, product.variants[0].id, this.cartOrders, value)
      }
    } else if (product.quantity !== 0 && value === 0) {
      this.removeItem(product.orderLineId.toString())
      setTimeout(() => {
        this.updateProducts(product.id, product.variants[0].id, this.cartOrders, 0);
      }, 1000)
    }
  }

  updateProducts(productId: number, variantId: number, orderData?: any, newQuantity?: number) {
    this.productsListSubject.pipe(take(1)).subscribe((productsArray) => {
      const updatedProductsArray = this.updateProductsInArray(
        productsArray,
        productId,
        variantId,
        orderData,
        newQuantity
      );

      // Update the BehaviorSubject with the updated array
      this.productsListSubject.next(updatedProductsArray);
    });
  }

  updateProductsInArray(
    productsArray: any[],
    productId: number,
    variantId: number,
    orderData?: any,
    newQuantity?: number
  ) {
    const updatedProductsArray = productsArray.map((product) => {
      if (product.products.some((productItem: any) => productItem.id === productId)) {
        const updatedProduct = { ...product };

        const productItemToUpdate = updatedProduct.products.find(
          (productItem: any) => productItem.id === productId
        );

        if (productItemToUpdate) {
          if (orderData && orderData.activeOrder) {

            if (!productItemToUpdate.quantity) {
              const orderLine = orderData.activeOrder.lines.find((line: any) => line.productVariant.id === productId);

              if (orderLine) {
                productItemToUpdate.quantity = orderLine.quantity;
              }
              if (!orderLine) {
                productItemToUpdate.quantity = 0
              }
            }
          }

          if (newQuantity !== undefined) {
            productItemToUpdate.quantity = newQuantity;
          }

          if (orderData) {
            const orderLine = orderData.activeOrder.lines.find((line: any) => line.productVariant.id === variantId);

            if (orderLine) {
              productItemToUpdate.orderLineId = orderLine.id;
            }
          }
        }
      }
      return product;
    });
    return updatedProductsArray
  }

  // end control table values


  // check enabled/disabled
  checkStock(product: any) {
    // console.log(product)
    if (product.variants[0].stockLevel === "OUT_OF_STOCK" && !product.customFields.availableFrom) {
      // console.log(`n tem o ${product.name}`)
      return true
    } else {
      return false
    }
  }

  // from cart drawer
  setItemQuantity(event: { itemId: string; quantity: number }) {
    if (this.cartOrders && this.cartOrders.activeOrder && this.cartOrders.activeOrder.state === 'ArrangingPayment') {
      this.dataService.mutate<TransitionToAddingItems.Mutation>(TRANSITION_TO_ADDING_ITEMS).subscribe(() => {
        if (0 < event.quantity) {
          this.adjustItemQuantity(event.itemId, event.quantity);
        } else {
          this.removeItem(event.itemId);
        }
      })
    } else {
      if (0 < event.quantity) {
        this.adjustItemQuantity(event.itemId, event.quantity);
      } else {
        this.removeItem(event.itemId);
      }
    }

  }

  private adjustItemQuantity(id: string, qty: number) {
    this.dataService
      .mutate<AdjustItemQuantity.Mutation, AdjustItemQuantity.Variables>(ADJUST_ITEM_QUANTITY, {
        id,
        qty,
      })
      .pipe(take(1))
      .subscribe(({ adjustOrderLine }) => {
        switch (adjustOrderLine.__typename) {
          case 'Order':
            break;
          case 'InsufficientStockError':
          case 'NegativeQuantityError':
          case 'OrderLimitError':
          case 'OrderModificationError':
            console.error(`Error status: ${adjustOrderLine.errorCode}`, adjustOrderLine.message)
            this.notificationService.error(adjustOrderLine.message).subscribe();
            break;
        }
      });
  }

  private removeItem(id: string) {
    this.dataService
      .mutate<RemoveItemFromCart.Mutation, RemoveItemFromCart.Variables>(REMOVE_ITEM_FROM_CART, {
        id,
      })
      .pipe(take(1))
      .subscribe();
  }

  // from product-list.component, only called to filter
  prodList() {
    // products per page
    const perPage = 5000;
    const collectionSlug$ = this.route.paramMap.pipe(
      map((pm) => pm.get('slug')),
      distinctUntilChanged(),
      tap((slug) => {
        this.stateService.setState('lastCollectionSlug', slug || null);
        this.currentPage = 0;
      }),
      shareReplay(1)
    );
    this.activeFacetValueIds$ = this.route.paramMap.pipe(
      map((pm) => getRouteArrayParam(pm, 'facets')),
      distinctUntilChanged((x, y) => x.toString() === y.toString()),
      tap(() => {
        this.currentPage = 0;
      }),
      shareReplay(1)
    );
    this.searchTerm$ = this.route.queryParamMap.pipe(
      map((pm) => pm.get('products') || ''),
      distinctUntilChanged(),
      shareReplay(1)
    );

    this.collection$ = collectionSlug$.pipe(
      switchMap((slug) => {
        if (slug) {
          return this.dataService
            .query<GetCollection.Query, GetCollection.Variables>(GET_COLLECTION, {
              slug,
            })
            .pipe(map((data) => data.collection));
        } else {
          return of(undefined);
        }
      }),
      shareReplay(1)
    );

    const assetPreviewPipe = new AssetPreviewPipe();

    this.mastheadBackground$ = this.collection$.pipe(
      map((c) => 'url(' + assetPreviewPipe.transform(c?.featuredAsset || undefined, 1000, 300) + ')'),
      map((style) => this.sanitizer.bypassSecurityTrustStyle(style))
    );

    this.breadcrumbs$ = this.collection$.pipe(
      map((collection) => {
        if (collection) {
          return collection.breadcrumbs;
        } else {
          return [
            {
              id: '',
              name: 'Home',
            },
            {
              id: '',
              name: 'Suchen',
            },
          ];
        }
      })
    );

    const triggerFetch$ = combineLatest(this.collection$, this.activeFacetValueIds$, this.searchTerm$, this.refresh);
    const getInitialFacetValueIds = () => {
      combineLatest(this.collection$, this.searchTerm$)
        .pipe(
          take(1),
          switchMap(([collection, term]) => {
            return this.dataService.query<SearchProducts.Query, SearchProducts.Variables>(SEARCH_PRODUCTS, {
              input: {
                term,
                groupByProduct: true,
                collectionId: collection?.id,
                facetValueOperator: LogicalOperator.AND,
                take: perPage,
                skip: this.currentPage * perPage,
              },
            });
          })
        )
        .subscribe((data) => {
          this.facetValues = data.search.facetValues;
          this.unfilteredTotalItems = data.search.totalItems;
        });
    };
    this.loading$ = merge(triggerFetch$.pipe(mapTo(true)));
    const queryResult$ = triggerFetch$.pipe(
      switchMap(([collection, facetValueIds, term]) => {
        return this.dataService
          .query<SearchProducts.Query, SearchProducts.Variables>(SEARCH_PRODUCTS, {
            input: {
              term,
              groupByProduct: true,
              collectionId: collection?.id,
              facetValueIds,
              facetValueOperator: LogicalOperator.AND,
              take: perPage,
              skip: this.currentPage * perPage,
            },
          })
          .pipe(
            tap((data) => {
              if (facetValueIds.length === 0) {
                this.facetValues = data.search.facetValues;
                this.unfilteredTotalItems = data.search.totalItems;
              } else if (!this.facetValues) {
                getInitialFacetValueIds();
              } else {
                this.facetValues = this.facetValues.map((fv) => fv);
              }
            })
          );
      }),
      shareReplay(1)
    );

    this.loading$ = merge(triggerFetch$.pipe(mapTo(true)), queryResult$.pipe(mapTo(false)));

    const RESET = 'RESET';
    const items$ = (this.products$ = queryResult$.pipe(map((data) => data.search.items)));
    const reset$ = merge(collectionSlug$, this.activeFacetValueIds$, this.searchTerm$).pipe(mapTo(RESET), skip(1), share());
    this.products$ = merge(items$, reset$).pipe(
      scan<SearchProducts.Items[] | string, SearchProducts.Items[]>((acc, val) => {
        if (typeof val === 'string') {
          return [];
        } else {
          return acc.concat(val);
        }
      }, [] as SearchProducts.Items[])
    );
    this.totalResults$ = queryResult$.pipe(map((data) => data.search.totalItems));
    this.displayLoadMore$ = combineLatest(this.products$, this.totalResults$).pipe(
      map(([products, totalResults]) => {
        return 0 < products.length && products.length < totalResults;
      })
    );
  }

  // debugProduct(product: any) {
  //   console.log(product)
  // }

  // debugButton(product: any) {
  //   console.log('DEBUG');
  //   console.log(this.productsList)
  // }
}
