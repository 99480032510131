<div class="product-card">
    <ng-template [ngIf]="product" [ngIfElse]="placeholder">
        <a [routerLink]="['/product', product.slug]" class="image-wrapper">
            <img
                *ngIf="product.productAsset"
                class="product-image"
                [src]="product.productAsset | assetPreview: 260:300"
            />
            <img
                *ngIf="!product.productAsset"
                class="product-image placeholder-image"
                src="../../../../assets/product_placeholder.jpg "
            />
        </a>
        <div class="text-center pt-3">
            <a [routerLink]="['/product', product.slug]" class="product-name">
                {{ product.productName }}
            </a>
            <div class="price">
                <ng-template
                    [ngIf]="product.variants[0].stockLevel == 'OUT_OF_STOCK'"
                    [ngIfElse]="onStock"
                >
                    <p style="margin-bottom: 0;">Ab {{ product.price.min | formatPrice }}</p>
                    <span class="alert-danger small">Nicht auf Lager</span>
                    <p class="small" *ngIf="product.customFields.availableFrom">Verfügbar ab {{ product.customFields.availableFrom }}</p>
                </ng-template>
                <ng-template #onStock
                >
                    <p style="margin-bottom: 0;">Ab {{ product.price.min | formatPrice }}</p>
                    <p class="small" *ngIf="product.customFields.availableFrom">Verfügbar ab {{ product.customFields.availableFrom }}</p>
                </ng-template>
                <!--<ng-template #range>
                    Ab {{ product.price.max | formatPrice }}
                </ng-template>-->
            </div>
        </div>
    </ng-template>
    <ng-template #placeholder>
        <div class="placeholder-image loading-placeholder"></div>
        <div class="card-body">
            <div class="placeholder-title loading-placeholder"></div>
            <div class="placeholder-body loading-placeholder"></div>
        </div>
    </ng-template>

    <!-- <button (click)="debugClick()">Debug</button> -->
</div>
