<div class="container">
    <vsf-collection-breadcrumbs
        [breadcrumbs]="breadcrumbs"
        *ngIf="product"
        class="mb-2"
        [linkLast]="true"
    ></vsf-collection-breadcrumbs>
    <ng-template [ngIf]="product" [ngIfElse]="placeholder">
        <div class="row pt-4">
            <div class="col-12 col-md-6 text-center">
                <vsf-asset-gallery
                    [assets]="product.assets"
                    [selectedAssetId]="product.featuredAsset?.id"
                ></vsf-asset-gallery>
            </div>
            <div class="col-12 col-md-6" #zoomPreviewArea>
                <h2 class="display-5 product-name pt-2">{{ product.name }}</h2>
                <div class="purchase-options">
                    <div class="variant-selector" *ngIf="product.variants.length > 1">
                        <select [(ngModel)]="selectedVariant" class="form-control mb-3">
                            <option *ngFor="let variant of product.variants" [ngValue]="variant">
                                {{ variant.name }}
                            </option>
                        </select>
                    </div>
                    <ng-template
                            [ngIf]="product.variants[0].stockLevel == 'OUT_OF_STOCK'"
                            [ngIfElse]="onStock"
                    >
                        <span class="alert-danger">Nicht auf Lager</span>
                        <p class="small" *ngIf="product.customFields.availableFrom">Verfügbar ab {{ product.customFields.availableFrom }}</p>
                    </ng-template>
                    <ng-template #onStock
                    >
                        <div class="price mr-3">
                            Ab
                            {{ selectedVariant?.price | formatPrice }}
                            <small>zzgl. MwSt.</small>
                        </div>
                        <p class="small mr-3 mb-0" *ngIf="product.customFields.availableFrom">Verfügbar ab {{ product.customFields.availableFrom }}</p>
                        <div class="add-to-cart-row mb-2">
                            <div class="qty mr-2 mt-2">
                                <input
                                        type="number"
                                        min="0"
                                        max="99"
                                        placeholder="Menge"
                                        [(ngModel)]="qty"
                                        class="form-control"
                                />
                            </div>
                            <button
                                    [disabled]="qty === 0"
                                    class="btn btn-outline-primary mt-2"
                                    (click)="addToCart(selectedVariant, qty)"
                            >
                                dem Warenkorb hinzufügen
                            </button>
                        </div>
                    </ng-template>
                </div>
                <div class="description" [innerHTML]="product.description"></div>
            </div>
        </div>
    </ng-template>
    <ng-template #placeholder>
        <div class="placeholder-breadcrumb loading-placeholder"></div>
        <div class="row">
            <div class="col-12 col-md-6 text-center">
                <div class="product-image">
                    <div class="placeholder-image loading-placeholder"></div>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="placeholder-title loading-placeholder"></div>
                <div class="placeholder-description loading-placeholder"></div>
            </div>
        </div>
    </ng-template>
</div>

<ng-template #addedToCartTemplate let-variant="variant" let-quantity="quantity" let-close="closeFn">
    <div class="add-to-cart-notification">
        <div class="thumb mr-2">
            <img
                class=""
                [src]="
                    (variant.featuredAsset?.preview || product.featuredAsset?.preview) +
                    '?preset=tiny'
                "
                alt="product thumbnail"
            />
        </div>
        <div>{{ quantity }} x {{ variant.name }}</div>
    </div>
    <button
        class="btn btn-sm btn-outline-primary btn-block"
        (click)="viewCartFromNotification(close)"
    >
        Warenkorb ansehen
    </button>
</ng-template>
