import { Component, Injectable, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { of, Subscription } from 'rxjs';
import { filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import {
  AddToCart,
  GetActiveOrder,
  GetProductDetail,
  SetCustomerForOrder,
  SetShippingMethod,
  TransitionToAddingItems,
  TransitionToArrangingPayment,
} from '../../../common/generated-types';
import { notNullOrUndefined } from '../../../common/utils/not-null-or-undefined';
import { DataService } from '../../providers/data/data.service';
import { NotificationService } from '../../providers/notification/notification.service';
import { StateService } from '../../providers/state/state.service';

import { ADD_TO_CART, GET_PRODUCT_DETAIL } from './product-detail.graphql';
import { TRANSITION_TO_ADDING_ITEMS } from '../../../checkout/components/checkout-process/checkout-process.graphql';
import { GET_ACTIVE_ORDER } from '../cart-drawer/cart-drawer.graphql';
import {
  SET_CUSTOMER_FOR_ORDER,
  SET_SHIPPING_METHOD,
  TRANSITION_TO_ARRANGING_PAYMENT,
} from '../../../checkout/components/checkout-shipping/checkout-shipping.graphql';
@Injectable({
  providedIn: 'root',
})
@Component({
  selector: 'vsf-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss'],
})
export class ProductDetailComponent implements OnInit, OnDestroy {
  product: GetProductDetail.Product;
  selectedAsset: { id: string; preview: string };
  selectedVariant: GetProductDetail.Variants;
  qty = 1;
  breadcrumbs: GetProductDetail.Breadcrumbs[] | null = null;
  @ViewChild('addedToCartTemplate', { static: true })
  private addToCartTemplate: TemplateRef<any>;
  private sub: Subscription;
  order: any;

  constructor(
    private dataService: DataService,
    private stateService: StateService,
    private notificationService: NotificationService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    const lastCollectionSlug$ = this.stateService.select((state) => state.lastCollectionSlug);
    const productSlug$ = this.route.paramMap.pipe(
      map((paramMap) => paramMap.get('slug')),
      filter(notNullOrUndefined)
    );

    this.sub = productSlug$
      .pipe(
        switchMap((slug) => {
          return this.dataService.query<GetProductDetail.Query, GetProductDetail.Variables>(GET_PRODUCT_DETAIL, {
            slug,
          });
        }),
        map((data) => data.product),
        filter(notNullOrUndefined),
        withLatestFrom(lastCollectionSlug$)
      )
      .subscribe(([product, lastCollectionSlug]) => {
        this.product = product;
        if (this.product.featuredAsset) {
          this.selectedAsset = this.product.featuredAsset;
        }
        this.selectedVariant = product.variants[0];
        const collection = this.getMostRelevantCollection(product.collections, lastCollectionSlug);
        this.breadcrumbs = collection ? collection.breadcrumbs : [];
      });
    this.dataService
      .query<GetActiveOrder.Query, GetActiveOrder.Variables>(GET_ACTIVE_ORDER, {}, 'network-only')
      .subscribe((res) => (this.order = res.activeOrder));
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  addToCart(variant: GetProductDetail.Variants, qty: number) {
    if (this.order?.state === 'ArrangingPayment') {
      this.dataService.mutate<TransitionToAddingItems.Mutation>(TRANSITION_TO_ADDING_ITEMS).subscribe(() => {
        this.addProductToCart(variant, qty);
      });
    } else {
      this.addProductToCart(variant, qty);
    }
  }

  addProductToCart(variant: GetProductDetail.Variants, qty: number) {
    this.dataService
      .mutate<AddToCart.Mutation, AddToCart.Variables>(ADD_TO_CART, {
        variantId: variant.id,
        qty,
      })
      .subscribe(({ addItemToOrder }) => {
        switch (addItemToOrder.__typename) {
          case 'Order':
            this.stateService.setState('activeOrderId', addItemToOrder ? addItemToOrder.id : null);
            if (variant) {
              this.notificationService
                .notify({
                  title: 'Artikel hinzugefügt',
                  type: 'info',
                  duration: 3000,
                  templateRef: this.addToCartTemplate,
                  templateContext: {
                    variant,
                    quantity: qty,
                  },
                })
                .subscribe();
              this.stateService
                .select((state) => state.signedIn)
                .pipe(
                  mergeMap((signedIn) => (!signedIn ? this.setCustomerForOrder() || of({}) : of({}))),
                  mergeMap(() =>
                    this.dataService.mutate<SetShippingMethod.Mutation, SetShippingMethod.Variables>(SET_SHIPPING_METHOD, {
                      id: '2',
                    })
                  )
                )
                .subscribe((data) => { });
            }
            break;
          case 'OrderModificationError':
          case 'OrderLimitError':
          case 'NegativeQuantityError':
          case 'InsufficientStockError':
            console.error(`Error status: ${addItemToOrder.errorCode}`, addItemToOrder.message)
            this.notificationService.error(addItemToOrder.message).subscribe();
            break;
        }
      });
  }

  private setCustomerForOrder() {
    return this.dataService
      .mutate<SetCustomerForOrder.Mutation, SetCustomerForOrder.Variables>(SET_CUSTOMER_FOR_ORDER, {
        input: {
          emailAddress: '',
          firstName: '',
          lastName: '',
        },
      })
      .pipe(
        tap(({ setCustomerForOrder }) => {
          console.log('Customer order: ', setCustomerForOrder)
          if (setCustomerForOrder && setCustomerForOrder.__typename !== 'Order') {
            console.error(`Error status: ${(setCustomerForOrder as any).errorCode}`, (setCustomerForOrder as any).message)
            if (setCustomerForOrder.__typename !== 'EmailAddressConflictError') this.notificationService.error((setCustomerForOrder as any).message);
          }
        })
      );
  }

  viewCartFromNotification(closeFn: () => void) {
    this.stateService.setState('cartDrawerOpen', true);
    closeFn();
  }

  /**
   * If there is a collection matching the `lastCollectionId`, return that. Otherwise return the collection
   * with the longest `breadcrumbs` array, which corresponds to the most specific collection.
   */
  private getMostRelevantCollection(collections: GetProductDetail.Collections[], lastCollectionSlug: string | null) {
    const lastCollection = collections.find((c) => c.slug === lastCollectionSlug);
    if (lastCollection) {
      return lastCollection;
    }
    return collections.slice().sort((a, b) => {
      if (a.breadcrumbs.length < b.breadcrumbs.length) {
        return 1;
      }
      if (a.breadcrumbs.length > b.breadcrumbs.length) {
        return -1;
      }
      return 0;
    })[0];
  }
}
