<ng-container *ngIf="(productsList$ | async) !== 0">
  <div class="list-view">
    <!--Remove style to remove the scroll-->
    <div class="table">
      <div class="table-header">
        <div class="header-cell qty">Bestell-<br />menge</div>
        <div class="header-cell cultivar">Kultivar</div>
        <div class="header-cell">PZN</div>
        <div class="header-cell name">Produktbezeichnung</div>
        <div class="header-cell dominance">Dominanz</div>
        <div class="header-cell manufacturer">Anbaubetrieb</div>
        <div class="header-cell land">Anbau-<br />land</div>
        <div class="header-cell germReduction">Keim-<br />reduktion</div>
        <div class="header-cell weight">Gebinde-<br />größe</div>
        <div class="header-cell thccdb">THC/CBD (%)</div>
        <div class="header-cell bbd">MHD</div>
        <div class="header-cell price">AEP</div>
        <div class="header-cell priceKg">Preis/Gramm</div>
      </div>
      <div class="table-row" *ngFor="let product of productsList$ | async">
        <!-- Quantity -->
        <div class="table-cell">
          <div class="table-cell-inside" *ngFor="let product of product.products">
            <ng-container *ngIf="!product?.name?.includes('Postkarte')">
              <!-- <button (click)="debugProduct(product)" >Debug Product</button> -->
              <div class="quantity-control">
                <button (click)="decreaseValue(product)" >-</button>
  
                <input type="number" [value]="product.quantity" (change)="set($event, product)" />
                <button (click)="increaseValue(product)" >+</button>
                <!-- add [disabled]="checkStock(product)"  in the inputs and button do disable if needed, removed
                removed because it's now hidden the line that don't have in stock-->
              </div>
            </ng-container>
          </div>
        </div>

        <!-- Cultivar -->
        <div class="table-cell solo">
          <div class="kult" *ngIf="product.cultivar">
            <!-- here  -->
            <img
            *ngIf="product.products[0].collections[0].featuredAsset.preview"
            [src]="product.products[0].collections[0].featuredAsset.preview"
            class="logo"
            alt="Canify pure or basic"
            style="max-height: 24px; margin-right: 8px;"
            />
              {{ product.cultivar }}
         
          </div>
        </div>

        <!-- PZN  -->
        <div class="table-cell">
          <div class="table-cell-inside pzn" *ngFor="let product of product.products">
            <ng-container *ngIf="!product?.name?.includes('Postkarte') && product.customFields.availableFrom else pznOnStock">
              <p class="solo-cell-inside">V: {{ product.customFields.pzn }}</p>
              <p class="availableFrom">Verfügbar ab <span>{{ product.customFields.availableFrom }}</span></p>
            </ng-container>
            <ng-template #pznOnStock>
              <p class="solo-cell-inside">{{ product.customFields.pzn }}</p>
            </ng-template>
          </div>
        </div>

        <!-- Table Name or Name of the first product  -->
        <div class="table-cell solo">
          <p class="solo-cell-inside">
            {{ product.products[0].customFields.tableName ? product.products[0].customFields.tableName : removeWeightFromName(product.products[0].name) }}
          </p>
        </div>

        <!-- Dominance -->
        <div class="table-cell solo">{{ product.products[0].customFields.dominance ? product.products[0].customFields.dominance : '-' }}</div>

        <!-- Manufacturer -->
        <div class="table-cell solo">
          <p class="solo-cell-inside">
            {{ product.products[0].customFields.manufacturer ?  product.products[0].customFields.manufacturer : '-'}}
          </p>
        </div>

        <!-- Cultivation land -->
        <div class="table-cell solo">
          <p class="solo-cell-inside">
            {{ product.products[0].customFields.land ? product.products[0].customFields.land : '-' }}
          </p>
        </div>

        <!-- Germ Reduction -->
        <div class="table-cell solo">
          <p class="solo-cell-inside">
            {{ product.products[0].customFields.germReduction ? product.products[0].customFields.germReduction : '-' }}
          </p>
        </div>

        <!-- Weight -->
        <div class="table-cell">
          <div class="table-cell-inside" *ngFor="let product of product.products">
            <ng-container *ngIf="!product?.name?.includes('Postkarte')">
              {{ product.customFields.weight }}g
            </ng-container>
          </div>
        </div>

        <!-- THC/CDB -->
        <div class="table-cell">
          <!-- old version -->
          <!-- <div class="table-cell-inside thccdb" *ngFor="let product of product.products" [ngClass]="!product.customFields.availableFrom && !checkStock(product) ? 'addBr' : ''">
            <ng-container *ngIf="!product.customFields.availableFrom && !product?.name?.includes('Postkarte') && !checkStock(product) else availableFrom">
              {{ product.customFields.thcCbd }}
            </ng-container>
            <ng-template #availableFrom>
              <ng-container *ngIf="!checkStock(product) else noStock">
                Verfügbar ab
              </ng-container>
              <ng-template #noStock>
                Nicht auf
              </ng-template>
            </ng-template>
          </div> -->
          <div class="table-cell-inside" *ngFor="let product of product.products">
            <ng-container *ngIf="!product?.name?.includes('Postkarte') && product.customFields.thcCbd else noThcCdb">
              {{ product.customFields.thcCbd }}
            </ng-container>
            <ng-template #noThcCdb>
              folgt
            </ng-template>
          </div>
        </div>


        <!-- Best Before Date  -->
        <div class="table-cell">
          <!-- old version  -->
            <!-- <div class="table-cell-inside bbd" *ngFor="let product of product.products">
              <ng-container *ngIf="!product.customFields.availableFrom && !product?.name?.includes('Postkarte') && !checkStock(product) else availableFromWeek">
                {{ product.customFields.bbd }}
              </ng-container>
              <ng-template #availableFromWeek>
                <ng-container *ngIf="!checkStock(product) else noStockWeek">
                  {{ product.customFields.availableFrom }}
                </ng-container>
                <ng-template #noStockWeek>
                  Lager
                </ng-template>
              </ng-template>
            </div> -->
            <div class="table-cell-inside" *ngFor="let product of product.products">
              <ng-container *ngIf="!product?.name?.includes('Postkarte') && product.customFields.bbd else noBbd">
                {{ product.customFields.bbd }}
              </ng-container>
              <ng-template #noBbd>
                folgt
              </ng-template>
            </div>
        </div>


        <!-- Price -->
        <div class="table-cell">
          <div class="table-cell-inside" *ngFor="let product of product.products">
            <ng-container *ngIf="!product?.name?.includes('Postkarte')">
              <p class="solo-cell-inside">
                {{ product.variants[0].price / 100 | currency : 'EUR' : 'symbol' : '1.2-2' }}
              </p>
            </ng-container>
          </div>
        </div>

        <!-- Price / Weight  -->
        <div class="table-cell">
          <div class="table-cell-inside" *ngFor="let product of product.products">
            <ng-container *ngIf="!product?.name?.includes('Postkarte')">
              {{ product.variants[0].price / 100 / product.customFields.weight | currency : 'EUR' : 'symbol' : '1.2-2' }}
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <p>V = vorbestellbar</p>
  </div>
  <div class="mt-3 float-left">
    <button class="btn btn-block btn-primary order-btn" [disabled]="!hasProducts" [routerLink]="['/checkout']">
        Bestellen
    </button>
    <!-- <button (click)="debugButton(product)">Debugger</button> -->
  </div>
</ng-container>
