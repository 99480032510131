<div class="card">
    <div class="card-body">
        <h6 class="card-title" *ngIf="title">{{ title }}</h6>
        <ul class="list-unstyled mb-0 address-lines">
            <li>{{ address.fullName }}</li>
            <li><b>{{ address.company }}</b></li>
            <li>{{ address.streetLine1 }} {{ address.streetLine2 }}</li>
            <li>{{ address.postalCode }} {{ address.city }}</li>
            <li>{{ getCountryName() }}</li>
            <br/>
            <li>{{ address.phoneNumber }}</li>
            <li>{{ localStorage.getItem('email') }}</li>
        </ul>
    </div>
    <!-- <div class="card-footer">
        <ng-content></ng-content>
    </div> -->
</div>
<br/>
