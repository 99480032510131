import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Injectable, Input, OnInit, Output } from '@angular/core';
import { merge, Observable } from 'rxjs';
import { map, shareReplay, switchMap, take } from 'rxjs/operators';

import { AdjustItemQuantity, GetActiveOrder, RemoveItemFromCart, TransitionToAddingItems } from '../../../common/generated-types';
import { DataService } from '../../providers/data/data.service';
import { NotificationService } from '../../providers/notification/notification.service';
import { StateService } from '../../providers/state/state.service';

import { ADJUST_ITEM_QUANTITY, GET_ACTIVE_ORDER, REMOVE_ITEM_FROM_CART } from './cart-drawer.graphql';
import { TRANSITION_TO_ADDING_ITEMS } from '../../../checkout/components/checkout-process/checkout-process.graphql';

@Injectable({
  providedIn: 'root',
})
@Component({
  selector: 'vsf-cart-drawer',
  templateUrl: './cart-drawer.component.html',
  styleUrls: ['./cart-drawer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartDrawerComponent implements OnInit {
  @HostBinding('class.visible')
  @Input()
  visible = false;
  @Output() close = new EventEmitter<void>();

  cart$: Observable<GetActiveOrder.ActiveOrder | null | undefined>;
  isEmpty$: Observable<boolean>;
  order: any;

  constructor(public dataService: DataService, public stateService: StateService, private notificationService: NotificationService) { }

  ngOnInit() {
    this.cart$ = merge(
      this.stateService.select((state) => state.activeOrderId),
      this.stateService.select((state) => state.signedIn)
    ).pipe(
      switchMap(() => this.dataService.query<GetActiveOrder.Query, GetActiveOrder.Variables>(GET_ACTIVE_ORDER, {}, 'network-only')),
      map((data) => data.activeOrder),
      shareReplay(1)
    );
    this.isEmpty$ = this.cart$.pipe(map((cart) => !cart || cart.lines.length === 0));
    this.dataService
      .query<GetActiveOrder.Query, GetActiveOrder.Variables>(GET_ACTIVE_ORDER, {}, 'network-only')
      .subscribe((res) => (this.order = res.activeOrder));
  }

  setQuantity(event: { itemId: string; quantity: number }) {
    if (this.order.state === 'ArrangingPayment') {
      this.dataService.mutate<TransitionToAddingItems.Mutation>(TRANSITION_TO_ADDING_ITEMS).subscribe(() => {
        this.setItemQuantity(event);
      });
    } else {
      this.setItemQuantity(event);
    }
  }

  setItemQuantity(event: { itemId: string; quantity: number }) {
    if (0 < event.quantity) {
      this.adjustItemQuantity(event.itemId, event.quantity);
    } else {
      this.removeItem(event.itemId);
    }
  }

  private adjustItemQuantity(id: string, qty: number) {
    this.dataService
      .mutate<AdjustItemQuantity.Mutation, AdjustItemQuantity.Variables>(ADJUST_ITEM_QUANTITY, {
        id,
        qty,
      })
      .pipe(take(1))
      .subscribe(({ adjustOrderLine }) => {
        switch (adjustOrderLine.__typename) {
          case 'Order':
            break;
          case 'InsufficientStockError':
          case 'NegativeQuantityError':
          case 'OrderLimitError':
          case 'OrderModificationError':
            console.error(`Error status: ${adjustOrderLine.errorCode}`, adjustOrderLine.message)
            this.notificationService.error(adjustOrderLine.message).subscribe();
            break;
        }
      });
  }

  private removeItem(id: string) {
    this.dataService
      .mutate<RemoveItemFromCart.Mutation, RemoveItemFromCart.Variables>(REMOVE_ITEM_FROM_CART, {
        id,
      })
      .pipe(take(1))
      .subscribe();
  }
}
