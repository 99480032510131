import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Address, Country, OrderAddress } from '../../../common/generated-types';

@Component({
    selector: 'vsf-address-form',
    templateUrl: './address-form.component.html',
    styleUrls: ['./address-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressFormComponent implements OnChanges {

    @Input() availableCountries: Country.Fragment[];
    @Input() address: OrderAddress.Fragment | Address.Fragment;

    addressForm: FormGroup;
    showError: boolean = false

    // Defaults
    regex = {
        letter: /[a-zA-ZäöüÄÖÜß\s-]/,
        number: /[0-9\s+]/,
        letterAndNumber: /[a-zA-ZäöüÄÖÜß0-9\s-]/,
        letters: '^(?![\\s.]+$)[a-zA-ZäöüÄÖÜß\\s-]*$',
        numbers: '^(?![\\s]+$)[0-9\\s+]*$',
        numbersWithoutSpaces: /^\d+$/,
        lettersAndNumbers: '^(?![\\s.]+$)[a-zA-ZäöüÄÖÜß0-9\\s-]*$'
    }

    constructor(private formBuilder: FormBuilder, public changeDetector: ChangeDetectorRef) {
        this.addressForm = this.formBuilder.group({
            streetLine1: [null, [Validators.required, Validators.minLength(2), Validators.pattern(this.regex.lettersAndNumbers)]],
            streetLine2: [null, [Validators.required, Validators.pattern(this.regex.lettersAndNumbers)]],
            city: [null, [Validators.required, Validators.minLength(2), Validators.pattern(this.regex.letters)]],
            province: [null, Validators.required],
            postalCode: [null, [Validators.required, Validators.minLength(4), Validators.pattern(this.regex.lettersAndNumbers)]],
            countryCode: [null, Validators.required]
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('address' in changes && this.addressForm && this.address) {
            this.addressForm.patchValue(this.address, { });
        }
        const country = this.address && this.address.country;
        if (country && this.availableCountries) {
            if (country && typeof country !== 'string') {
                this.addressForm.patchValue({
                    countryCode: country.code,
                });
            } else {
                const matchingCountry = this.availableCountries.find(c => c.name === country);
                if (matchingCountry) {
                    this.addressForm.patchValue({
                        countryCode: matchingCountry.code,
                    });
                }
            }
        }
    }

}
