import {gql} from 'apollo-angular';


import { ERROR_RESULT_FRAGMENT } from '../../../common/graphql/fragments.graphql';

export const SIGN_IN = gql`
    mutation Authenticate($input: AuthenticationInput!, $rememberMe: Boolean) { 
          authenticate(
              input: $input
              rememberMe: $rememberMe
          ) {
                ...on CurrentUser {
                    id
                }
                ...ErrorResult
          }
    }
    ${ERROR_RESULT_FRAGMENT}
`;
