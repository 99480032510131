<form [formGroup]="addressForm">
    <div class="form-group row">
        <label for="streetLine1" class="col-sm-5 col-form-label">Straße *</label>
        <div class="col-sm-7">
            <input class="form-control" formControlName="streetLine1" id="streetLine1"
                   [minLength]="2"
                   [maxLength]="50"
                   required>
            <span class="text-danger" *ngIf="addressForm.get('streetLine1')?.errors?.required && showError">
                Straße ist erforderlich.
            </span>
            <span class="text-danger" *ngIf="addressForm.get('streetLine1')?.errors?.minlength && showError">
                Straße muss aus mindestens 2 Zeichen bestehen.
            </span>
            <span class="text-danger" *ngIf="addressForm.get('streetLine1')?.errors?.pattern && showError">
                Straße darf kein Sonderzeichen enthalten.
            </span>
        </div>
    </div>
    <div class="form-group row">
        <label for="streetLine2" class="col-sm-5 col-form-label">Hausnummer *</label>
        <div class="col-sm-7">
            <input class="form-control" formControlName="streetLine2" id="streetLine2" required>
            <span class="text-danger" *ngIf="addressForm.get('streetLine2')?.errors?.required && showError">
                Hausnummer ist erforderlich.
            </span>
            <span class="text-danger" *ngIf="addressForm.get('streetLine2')?.errors?.pattern && showError">
                Hausnummer darf kein Sonderzeichen enthalten.
            </span>
        </div>
    </div>
    <div class="form-group row">
        <label for="postalCode" class="col-sm-5 col-form-label">PLZ *</label>
        <div class="col-sm-7">
            <input class="form-control" formControlName="postalCode" id="postalCode"
                   [maxLength]="10"
                   required>
            <span class="text-danger" *ngIf="addressForm.get('postalCode')?.errors?.required && showError">
                PLZ ist erforderlich.
            </span>
            <span class="text-danger" *ngIf="addressForm.get('postalCode')?.errors?.minlength && showError">
                PLZ muss aus mindestens 4 Zeichen bestehen.
            </span>
            <span class="text-danger" *ngIf="addressForm.get('postalCode')?.errors?.pattern && showError">
                PLZ ist ungültig.
            </span>
        </div>
    </div>
    <div class="form-group row">
        <label for="city" class="col-sm-5 col-form-label">Stadt *</label>
        <div class="col-sm-7">
            <input class="form-control" formControlName="city" id="city" required>
            <span class="text-danger" *ngIf="addressForm.get('city')?.errors?.required && showError">
                Stadt ist erforderlich.
            </span>
            <span class="text-danger" *ngIf="addressForm.get('city')?.errors?.minlength && showError">
                Stadt muss aus mindestens 2 Zeichen bestehen.
            </span>
            <span class="text-danger" *ngIf="addressForm.get('city')?.errors?.pattern && showError">
                Stadt ist ungültig.
            </span>
        </div>
    </div>
    <div class="form-group row">
        <label for="province" class="col-sm-5 col-form-label">Bundesland *</label>
        <div class="col-sm-7">
            <select class="form-control" formControlName="province" id="province" required>
                <option value="DE-BY">Bayern</option>
                <option value="DE-BE">Berlin</option>
                <option value="DE-BB">Brandenburg</option>
                <option value="DE-HB">Bremen</option>
                <option value="DE-HH">Hamburg</option>
                <option value="DE-HE">Hessen</option>
                <option value="DE-MV"
                >Mecklenburg-Vorpommern
                </option
                >
                <option value="DE-NI">Niedersachsen</option>
                <option value="DE-NW"
                >Nordrhein-Westfalen
                </option
                >
                <option value="DE-RP"
                >Rheinland-Pfalz
                </option
                >
                <option value="DE-SL">Saarland</option>
                <option value="DE-SN">Sachsen</option>
                <option value="DE-ST"
                >Sachsen-Anhalt
                </option
                >
                <option value="DE-SH"
                >Schleswig-Holstein
                </option
                >
                <option value="DE-TH">Thüringen</option>
            </select>
            <span class="text-danger" *ngIf="addressForm.get('province')?.errors?.required && showError">
                Bundesland ist erforderlich.
            </span>
        </div>
    </div>
    <div class="form-group row">
        <label for="countryCode" class="col-sm-5 col-form-label">Land *</label>
        <div class="col-sm-7">
            <select class="custom-select" formControlName="countryCode" id="countryCode" required>
                <option *ngFor="let country of availableCountries" [ngValue]="country.code" selected>
                    {{ country.name }}
                </option>
            </select>
            <span class="text-danger" *ngIf="addressForm.get('countryCode')?.errors?.required && showError">
                Land ist erforderlich.
            </span>
        </div>
    </div>
</form>
