<div class="product-image">
    <div class="main-image">
        <img
            *ngIf="selectedAsset"
            [src]="selectedAsset | assetPreview: 540"
            #mainPreview
            class="img-fluid main-preview"
            height="500"
            (click)="openImage(selectedAsset.id)"
        />
        <!--Placeholder -->
        <img
            *ngIf="!selectedAsset"
            src="../../../../assets/product_placeholder.jpg "
            #mainPreview
            class="img-fluid main-preview"
            height="500"
            alt="Platzhalter Bild"
        />
    </div>
    <div class="alt-images" *ngIf="1 < assets.length">
        <div
            *ngFor="let asset of assets"
            class="alt-image"
            [class.selected]="selectedAsset.id === asset.id"
            (click)="selectImage(asset.id)"
        >
            <img [src]="asset | assetPreview: 'tiny'" />
        </div>
    </div>
</div>

<!-- Root element of PhotoSwipe. Must have class pswp. -->
<!--<ng-template #overlayTemplate>-->
<div class="pswp" tabindex="-1" role="dialog" aria-hidden="true" #photoswipeModal>
    <!-- Background of PhotoSwipe.
         It's a separate element as animating opacity is faster than rgba(). -->
    <div class="pswp__bg"></div>
    <!-- Slides wrapper with overflow:hidden. -->
    <div class="pswp__scroll-wrap">
        <!-- Container that holds slides.
            PhotoSwipe keeps only 3 of them in the DOM to save memory.
            Don't modify these 3 pswp__item elements, data is added later on. -->
        <div class="pswp__container">
            <div class="pswp__item"></div>
            <div class="pswp__item"></div>
            <div class="pswp__item"></div>
        </div>
        <!-- Default (PhotoSwipeUI_Default) interface on top of sliding area. Can be changed. -->
        <div class="pswp__ui pswp__ui--hidden">
            <div class="pswp__top-bar">
                <!--  Controls are self-explanatory. Order can be changed. -->
                <div class="pswp__counter"></div>
                <button class="pswp__button pswp__button--close" title="Close (Esc)"></button>
                <button class="pswp__button pswp__button--share" title="Share"></button>
                <button class="pswp__button pswp__button--fs" title="Toggle fullscreen"></button>
                <button class="pswp__button pswp__button--zoom" title="Zoom in/out"></button>

                <!-- Preloader demo https://codepen.io/dimsemenov/pen/yyBWoR -->
                <!-- element will get class pswp__preloader--active when preloader is running -->
                <div class="pswp__preloader">
                    <div class="pswp__preloader__icn">
                        <div class="pswp__preloader__cut">
                            <div class="pswp__preloader__donut"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
                <div class="pswp__share-tooltip"></div>
            </div>

            <button
                class="pswp__button pswp__button--arrow--left"
                title="Previous (arrow left)"
            ></button>
            <button
                class="pswp__button pswp__button--arrow--right"
                title="Next (arrow right)"
            ></button>

            <div class="pswp__caption">
                <div class="pswp__caption__center"></div>
            </div>
        </div>
    </div>
</div>
<!--</ng-template>-->
