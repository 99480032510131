import { Component, OnInit } from "@angular/core"
import { ActivatedRoute, Router, RouterEvent } from "@angular/router"
import { Observable } from "rxjs"
import { filter, map } from "rxjs/operators"
import { StateService } from "./core/providers/state/state.service"
import { SignIn } from "./common/generated-types"
import { SIGN_IN } from "./shared/components/sign-in/sign-in.graphql"
import { DataService } from "./core/providers/data/data.service"
import { environment } from "../environments/environment"
import jwt_decode from "jwt-decode"
import { JwtHelperService } from "@auth0/angular-jwt"
import {HttpClient} from "@angular/common/http";

/**
 * Delay helper function
 *
 * @param ms
 */
function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms))
}

/**
 * Token payload model
 */
export interface TokenPayload {
    _id: string
    email: string
    group: string
    role: string
    acl: object
    status: string
    pharmacyIds: string[]
}

@Component({
    selector: "sf-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
    cartDrawerVisible$: Observable<boolean>
    mobileNavVisible$: Observable<boolean>
    isHomePage$: Observable<boolean>
    loading: boolean = false
    signedIn: boolean = false
    accountUrl: string

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private dataService: DataService,
        private stateService: StateService,
        private jwtHelper: JwtHelperService,
        private httpClient: HttpClient
    ) {
        this.accountUrl = environment.accountUrl
    }

    /**
     * Sign in via token in the URL param
     */
    ngOnInit(): void {
        // Show spinner
        this.loading = true

        this.cartDrawerVisible$ = this.stateService.select((state) => state.cartDrawerOpen)
        this.mobileNavVisible$ = this.stateService.select((state) => state.mobileNavMenuIsOpen)
        this.isHomePage$ = this.router.events.pipe(
            filter<any>((event) => event instanceof RouterEvent),
            map((event: RouterEvent) => event.url === "/")
        )

        // Sign in via token
        this.route.queryParams.subscribe(async (params) => {
            // Is there a token param?
            if (params?.token) {
                const token = params.token

                if (token === 'DocCheck') {

                    if (params?.dc_timestamp) {
                    // if (params?.email) {
                        // @ts-ignore
                        // let now = parseInt(new Date().getTime())
                        // let diffMins = now - (parseInt(params?.dc_timestamp) + 1000)
                        //
                        // localStorage.setItem('accessToken', 'DocCheck')
                        // localStorage.setItem('dc_timestamp', params?.dc_timestamp)
                        //
                        // if (diffMins > 3600000) {
                        //     // window.location.href = environment.accountUrl
                        // } else {
                        //     // localStorage.setItem('accessToken', 'DocCheck')
                        //     // localStorage.setItem('dc_timestamp', params?.dc_timestamp)
                        // }
                        localStorage.setItem('accessToken', 'DocCheck')
                        localStorage.setItem('dc_timestamp', params?.dc_timestamp)
                        // this.stateService.setState("signedIn", true)
                        return this.router.navigate(["/"])
                        // localStorage.setItem('email', params?.email)
                    } else {
                        window.location.href = environment.accountUrl
                    }

                    // const signIn = {
                    //     email: 'admin@tekr.io',
                    //     password: '9N8j1ZhWtuTtsK51cn9Rck8VBj6Wa9yt'
                    // }

                    // this.httpClient.post<any>(environment.accountApiUrl + '/auth/sign-in/user', signIn,
                    //     {headers: {'X-Auth': 'Api-Key ' + environment.accountApiKey}}
                    // ).toPromise().then(
                    //     (signInResponse) => {
                    //         console.log(signInResponse)
                    //         localStorage.setItem('accountAccessToken', signInResponse?.accessToken)
                    //         const signInDocCheck = {
                    //             email: params?.email,
                    //             signInOrigin: 'DocCheck'
                    //         }
                            // this.httpClient.post<any>(environment.accountApiUrl + '/auth/sign-in/customer', signInDocCheck,
                            //     {headers: {'X-Auth': 'Api-Key ' + environment.accountApiKey}}
                            // ).toPromise().then(
                            //     (signInResponseDocCheck) => {
                            //         console.log(signInResponseDocCheck)
                            //         localStorage.setItem('accessToken', signInResponseDocCheck?.accessToken)
                            //
                            //         // Set app state
                            //         this.stateService.setState("signedIn", true)
                            //
                            //         // Navigate to shop home page
                            //         return this.router.navigate(["/?token=" + signInResponseDocCheck?.accessToken])
                            //     }
                            // ).catch(error => {
                            //     // // alert(error)
                            //     console.log(error)
                            //
                            //     // Set app state
                            //     // this.stateService.setState("signedIn", true)
                            //
                            //     // Navigate to shop home page
                            //     return this.router.navigate(["/"])
                            // })
                    //     }
                    // )
                } else {
                    // Redirect back if token has expired or is invalid
                    if (this.jwtHelper.isTokenExpired(token)) {
                        window.location.href = environment.accountUrl
                    }

                    console.log(token)

                    // Get token payload
                    const payload = jwt_decode<TokenPayload>(token)

                    console.log(payload)

                    // Guest session for employees
                    if (payload.group === 'user' && payload.role !== 'user' && payload.status == 'activated') {
                        localStorage.setItem("accessToken", token)

                        // Set app state
                        this.stateService.setState("signedIn", true)
                        // alert('why')

                        // Navigate to shop home page
                        return this.router.navigate(["/"])
                    }

                    // Check payload content before signing in
                    if (!payload.email || payload.status !== "activated") {
                        // alert('what')
                        window.location.href = environment.accountUrl
                    } else {
                        // Sign in mutation input
                        const signInInput = { canify: { token: token } }
                        // alert(JSON.stringify(signInInput))

                        // Sign in with token
                        this.dataService
                            .mutate<SignIn.Mutation, SignIn.Variables>(SIGN_IN, {
                                input: signInInput,
                                rememberMe: true,
                            })
                            .subscribe({
                                next: async ({ authenticate }) => {
                                    switch (authenticate.__typename) {
                                        case "CurrentUser":
                                            // Save token in local storage
                                            localStorage.setItem("accessToken", token)

                                            // alert('oi')
                                            // Set app state
                                            this.stateService.setState("signedIn", true)

                                            this.signedIn = true

                                            // Navigate to shop home page
                                            this.router.navigate(["/"])

                                            break
                                        case "NativeAuthStrategyError":
                                        case "InvalidCredentialsError":
                                            // alert('err')
                                            // Navigate to account home page
                                            window.location.href = environment.accountUrl
                                            break
                                    }
                                },
                            })
                    }
                }
            } else {
                // Since localStorage calls are synchronous
                // We have to delay execution in order to get the recently set accessToken
                await delay(1000)

                // Hide spinner
                this.loading = false

                // Read access token
                let token = localStorage.getItem("accessToken")

                // Redirect back if there is no access token
                if (!token) {
                    await delay(500)

                    token = localStorage.getItem("accessToken")
                    // window.location.href = environment.accountUrl
                }

                // Redirect back if token has expired or is invalid
                try {

                    if (token === 'DocCheck') {
                        // console.log(localStorage.getItem('dc_timestamp'))
                        if (localStorage.getItem('email')) {
                            // // @ts-ignore
                            // let now = new Date().valueOf()
                            // let diffMins = now - (parseInt(params?.dc_timestamp) - 1000)
                            //
                            // // console.log(diffMins)
                            //
                            // if (diffMins > 3600000) {
                            //     // localStorage.removeItem('accessToken')
                            //     // localStorage.removeItem('dc_timestamp')
                            //     // window.location.href = environment.accountUrl
                            // }

                        } else {
                            // window.location.href = environment.accountUrl
                        }
                    } else {
                        // @ts-ignore
                        if (this.jwtHelper.isTokenExpired(token)) {
                            window.location.href = environment.accountUrl
                        }
                    }

                } catch (error) {
                    window.location.href = environment.accountUrl
                }
            }
        })
    }

    openCartDrawer() {
        this.stateService.setState("cartDrawerOpen", true)
    }

    closeCartDrawer() {
        this.stateService.setState("cartDrawerOpen", false)
    }

    diffMinutes(date1: string | number | Date, date2: string | number | Date) {
        const d1 = new Date(date1).getTime()
        const d2 = new Date(date2).getTime()
        return Math.round((d2 - d1) / 60000) // Can use Math.floor or Math.ceil depends up to you
    }
}
