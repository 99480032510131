import { gql } from 'apollo-angular';
import { ASSET_FRAGMENT, CART_FRAGMENT, ERROR_RESULT_FRAGMENT } from '../../../common/graphql/fragments.graphql';

export const SEARCH_PRODUCTS_TABLE = gql`
  query {
    products(options: {}) {
      items {
        id
        name
        slug
        description
        collections {
          name
          featuredAsset {
            ...Asset
          }
        }
        variants {
          id
          name
          options {
            code
            name
          }
          sku
          productId
          price
          priceWithTax
          stockLevel
        }
        customFields {
          tableName
          cultivar
          pzn
          dominance
          weight
          availableFrom
          thcCbd
          bbd
          manufacturer
          land
          germReduction
        }
        facetValues {
          name
        }
      }
      totalItems
    }
  }
  ${ASSET_FRAGMENT}
`;
