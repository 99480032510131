<vsf-layout>
    <vsf-layout-header>
        <div class="header">
            <div class="container">
                <vsf-mobile-menu-toggle></vsf-mobile-menu-toggle>
                <a [routerLink]="['/']" class="logo-link d-none d-md-inline-block">
                    <img
                            src="assets/logo-on-dark.png"
                            class="logo"
                            alt="Canify"
                            style="max-height: 50px"
                    />
                </a>
                <div class="flex-grow-1 d-none d-sm-block"></div>
                <vsf-product-search-bar class="flex-grow-1" *ngIf="!loading"></vsf-product-search-bar>
                <vsf-account-link class="mr-2" *ngIf="!loading"></vsf-account-link>
                <vsf-cart-toggle (toggle)="openCartDrawer()" *ngIf="!loading"></vsf-cart-toggle>
            </div>
            <vsf-collections-menu *ngIf="!loading"></vsf-collections-menu>
        </div>
    </vsf-layout-header>
    <vsf-collections-menu-mobile
            [visible]="mobileNavVisible$ | async"
    ></vsf-collections-menu-mobile>
    <vsf-cart-drawer
            [visible]="cartDrawerVisible$ | async"
            (close)="closeCartDrawer()"
    ></vsf-cart-drawer>
    <div class="h-100 d-flex justify-content-center align-items-center" *ngIf="loading">
        <div class="py-4">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
    <div class="contents">
        <vsf-home-page *ngIf="isHomePage$ | async; else main"></vsf-home-page>
        <ng-template #main>
            <router-outlet></router-outlet>
        </ng-template>
    </div>

    <vsf-layout-footer>
        <div class="row mt-5 mb-5 footer">
            <!-- Logo  -->
            <div class="col-md-4 text-center text-md-left mb-4 mb-md-0">
                <a [routerLink]="['/']">
                    <img
                            src="../assets/logo-on-dark.png"
                            class="logo"
                            alt="Canify"
                            style="max-height: 50px"
                    />
                </a>
            </div>

            <!-- Naviagtion -->
            <div class="col-md-4 text-center text-md-left">
                <h4 class="footer_contact_heading">Navigation</h4>
                <ul class="list-unstyled">
                    <li><a href="{{accountUrl}}">Account</a></li>
                    <li *ngIf="signedIn"><a href="/account/orders">Bestellungen</a></li>
                    <li>
                        <a href="https://www.canify.com/#canify-pharma" target="_blank">Impressum</a>
                    </li>
                    <li>
                        <a href="https://www.canify.com/privacy-policy" target="_blank">Datenschutz</a>
                    </li>
                    <!--                    <li><a href="https://www.canify.com/privacy-policy" target="_blank">AGB</a></li>-->
                </ul>
            </div>

            <!-- Kontakt -->
            <div class="col-md-4 text-center text-md-left">
                <h4 class="footer_contact_heading">Canify AG</h4>
                <ul class="list-unstyled">
                    <li>Gewerbestraße 11</li>
                    <li>Herrsching 82211</li>
                    <li>GERMANY</li>
                </ul>
                <h4 class="footer_contact_heading">Zentrale</h4>
                <ul class="list-unstyled">
                    <li>kundenservice@canify.com</li>
                    <li>0800 1 420 420</li>
                </ul>
            </div>
        </div>
    </vsf-layout-footer>
</vsf-layout>
