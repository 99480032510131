<div *ngIf="breadcrumbs">
    <div *ngIf="breadcrumbs.length > 1">
        <nav aria-label="breadcrumb" *ngIf="breadcrumbs[1].name !== 'Suchen'">
            <ol class="breadcrumb">
                <li class="breadcrumb-item" *ngIf="breadcrumbs?.length > 1">
                    <a [routerLink]="['/']">Alle Produkte</a>
                </li>
                <li class="breadcrumb-item"
                    *ngFor="let breadcrumb of tail(breadcrumbs); last as isLast; first as isFirst"
                    [attr.aria-current]="isLast">
                    <ng-container *ngIf="isLast && !linkLast; else link">{{ breadcrumb.name }}</ng-container>
                    <ng-template #link>
                        <a [routerLink]="['/category', breadcrumb.slug]">{{ breadcrumb.name }}</a>
                    </ng-template>
                </li>
            </ol>
        </nav>
    </div>
</div>
